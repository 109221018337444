import { Component, OnInit } from '@angular/core';
import { BikeFormWrapperComponent } from '../../components/bike-form-wrapper/bike-form-wrapper.component';
import { PageTitleComponent } from '@base/components/page-title/page-title.component';
import { BikeCalculatorComponent } from '../../components/bike-calculator/bike-calculator.component';
import { BikeProductTypeEnum } from '@base/models/bike/bike-product-type.enum';
import { BikeCalculatorWithProductsComponent } from '../../components/bike-calculator-with-products/bike-calculator-with-products.component';

@Component({
  selector: 'app-bike-page',
  standalone: true,
  imports: [
    PageTitleComponent,
    BikeFormWrapperComponent,
    BikeCalculatorComponent,
    BikeCalculatorWithProductsComponent
  ],
  templateUrl: './bike-page.component.html',
  styleUrl: './bike-page.component.scss'
})
export class BikePageComponent implements OnInit {

  submitted = false;

  premium = 0;
  price = 0;
  isEbike = false;
  bikeProductType: BikeProductTypeEnum | null = null;

  showRechner = false;
  noPermission = false;

  servoAdvertiserId: string | null = null;

  hasProductChooser = true;

  presetValues: { isEbike: boolean | null, price: number } | null = null;

  ngOnInit() {
    const url = new URL(window.location.href);

    //string to boolean
    this.isEbike = url.searchParams.get('isEbike') == 'true';

    if (url.href.includes('public')) {
      this.handleCustomerViaPublic(url);
    } else if (url.href.includes('stella')) {
      this.handleCustomerViaApp(url);
    } else {
      this.handleCustomerViaMakler(url);
    }
  }

  private handleCustomerViaApp(url: URL): void {
    if (!url.searchParams.has('advertiserId')) {
      this.noPermission = true;
      return;
    }

    this.servoAdvertiserId = this.getAdvertiserId(url);
    this.hasProductChooser = false;
    this.showRechner = true;
  }

  private handleCustomerViaPublic(url: URL): void {
    if (!this.hasUrlAdvertiserId(url)) {
      this.noPermission = true;
      return;
    }

    this.servoAdvertiserId = this.getAdvertiserId(url);

    if (url.searchParams.get('products') === 'false') {
      this.hasProductChooser = false;
    }

    this.showRechner = true;
  }

  private handleCustomerViaMakler(url: URL): void {
    this.showRechner = false;

    if (!this.hasUrlAdvertiserId(url) || !url.searchParams.has('premium') || !url.searchParams.has('price')) {
      this.noPermission = true;
      return;
    }

    this.servoAdvertiserId = this.getAdvertiserId(url);

    this.price = Number(url.searchParams.get('price'));
    this.premium = Number(url.searchParams.get('premium'));
    this.noPermission = false;
  }

  // there are still some qr-codes which have an old maklerId / referrerId instead of the new advertiserId
  hasUrlAdvertiserId(url: URL): boolean {
    return url.searchParams.has('maklerId') || url.searchParams.has('advertiserId') || url.searchParams.has('referrerId');
  }

  getAdvertiserId(url: URL): string | null {
    if (url.searchParams.has('advertiserId')) {
      return url.searchParams.get('advertiserId')!;
    }

    if (url.searchParams.has('maklerId')) {
      return url.searchParams.get('maklerId')!;
    }

    if (url.searchParams.has('referrerId')) {
      return url.searchParams.get('referrerId')!;
    }

    return null;
  }

  onRequestOffer(event: {
    isEbike: boolean,
    price: number,
    premium: number,
    bikeProductType: BikeProductTypeEnum | null
  }): void {
    this.price = event.price;
    this.premium = event.premium;
    this.isEbike = event.isEbike;
    this.bikeProductType = event.bikeProductType;
    this.showRechner = false;

    window.scroll({ top: 0, left: 0, behavior: 'instant' });
  }

  onNavigateBackFromForm($event: { isEbike: boolean | null, price: number }): void {
    this.showRechner = true;
    this.presetValues = $event;
  }
}
